import { NavLink } from "react-router-dom";
import "./Checkout.scss";
import { useEffect, useState } from "react";
const Footer = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="mb-4 mt-3 text-start col-md-12">
      <div className="gap-2">
        {isLoading ? (
          <div className="skeleton p-3">
            <div className="skeleton-text"></div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-3 col-6">
              <NavLink
                className="nav-link-gap"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal1"
                style={{ textDecorationColor: "white", color: "white" }}
              >
                Refund policy
              </NavLink>
            </div>
            <div className="col-md-3 col-6">
              <NavLink
                className=" nav-link-gap"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                style={{ textDecorationColor: "white", color: "white" }}
              >
                Shipping Policy
              </NavLink>
            </div>
            <div className="col-md-3 col-6">
              <NavLink
                className=" nav-link-gap"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal3"
                style={{ textDecorationColor: "white", color: "white" }}
              >
                Privacy policy
              </NavLink>
            </div>
            <div className="col-md-3 col-6">
              <NavLink
                className=" nav-link-gap"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal4"
                style={{ textDecorationColor: "white", color: "white" }}
              >
                Terms Of Service
              </NavLink>
            </div>
            <div className="col-md-3 col-12 text-start">
              <NavLink
                className=" nav-link-gap"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal5"
                style={{ textDecorationColor: "white", color: "white" }}
              >
                Contact Information
              </NavLink>
            </div>
          </div>
        )}
      </div>

      <div
        className="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ background: "rgb(251 214 214)" }}
            >
              <h1 className="modal-title " id="exampleModalLabel">
                Refund policy
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <p>
                We have a 30-day return policy, which means you have 30 days
                after receiving your item to request a return.
              </p>
              <p>
                To be eligible for a return, your item must be in the same
                condition that you received it, unworn or unused, with tags, and
                in its original packaging. You’ll also need the receipt or proof
                of purchase.
              </p>
              <p>
                To start a return, you can contact us at{" "}
                <span className="text-info">yasfibussiness@gmail.com. </span>
              </p>
              <p>
                If your return is accepted, we’ll send you a return shipping
                label, as well as instructions on how and where to send your
                package. Items sent back to us without first requesting a return
                will not be accepted.
              </p>
              <p>
                You can always contact us for any return question at{" "}
                <span className="text-info">yasfibussiness@gmail.com.</span>
              </p>
              <h5>Damages and issues</h5>
              <p>
                Please inspect your order upon reception and contact us
                immediately if the item is defective, damaged or if you receive
                the wrong item, so that we can evaluate the issue and make it
                right.
              </p>
              <h5>Exceptions / non-returnable items</h5>
              <p>
                Certain types of items cannot be returned, like perishable goods
                (such as food, flowers, or plants), custom products (such as
                special orders or personalized items), and personal care goods
                (such as beauty products). We also do not accept returns for
                hazardous materials, flammable liquids, or gases. Please get in
                touch if you have questions or concerns about your specific
                item.
              </p>
              <p>
                Unfortunately, we cannot accept returns on sale items or gift
                cards.
              </p>
              <h5>Exchanges</h5>
              <p>
                The fastest way to ensure you get what you want is to return the
                item you have, and once the return is accepted, make a separate
                purchase for the new item.
              </p>
              <h5>European Union 14 day cooling off period</h5>
              <p>
                Notwithstanding the above, if the merchandise is being shipped
                into the European Union, you have the right to cancel or return
                your order within 14 days, for any reason and without a
                justification. As above, your item must be in the same condition
                that you received it, unworn or unused, with tags, and in its
                original packaging. You’ll also need the receipt or proof of
                purchase.
              </p>
              <h5>Refunds</h5>
              <p>
                We will notify you once we’ve received and inspected your
                return, and let you know if the refund was approved or not. If
                approved, you’ll be automatically refunded on your original
                payment method within 10 business days. Please remember it can
                take some time for your bank or credit card company to process
                and post the refund too.
              </p>
              <p>
                If more than 15 business days have passed since we’ve approved
                your return, please contact us at{" "}
                <span className="text-info">yasfibussiness@gmail.com.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel3"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ background: "rgb(251 214 214)" }}
            >
              <h1 className="modal-title " id="exampleModalLabe2">
                Privacy Policy
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <p>
                Last updated:<span className="text-danger"> 01-05-2024</span>
              </p>
              <p>
                This Privacy Policy describes how Our collects, uses, and
                discloses your personal information when you visit, use our
                services, or make a purchase from www.yasfi.site or otherwise
                communicate with us . For purposes of this Privacy Policy, means
                you as the user of the Services, whether you are a customer,
                website visitor, or another individual whose information we have
                collected pursuant to this Privacy Policy.
              </p>
              <p>
                Please read this Privacy Policy carefully. By using and
                accessing any of the Services, you agree to the collection, use,
                and disclosure of your information as described in this Privacy
                Policy. If you do not agree to this Privacy Policy, please do
                not use or access any of the Services.
              </p>
              <h5>Changes to This Privacy Policy</h5>
              <p>
                We may update this Privacy Policy from time to time, including
                to reflect changes to our practices or for other operational,
                legal, or regulatory reasons. We will post the revised Privacy
                Policy on the Site, update the "Last updated" date and take any
                other steps required by applicable law.
              </p>
              <h5>How We Collect and Use Your Personal Information</h5>
              <p>
                To provide the Services, we collect and have collected over the
                past 12 months personal information about you from a variety of
                sources, as set out below. The information that we collect and
                use varies depending on how you interact with us.
              </p>
              <p>
                In addition to the specific uses set out below, we may use
                information we collect about you to communicate with you,
                provide the Services, comply with any applicable legal
                obligations, enforce any applicable terms of service, and to
                protect or defend the Services, our rights, and the rights of
                our users or others.
              </p>
              <p>What Personal Information We Collect</p>
              <p>
                The types of personal information we obtain about you depends on
                how you interact with our Site and use our Services. When we use
                the term "personal information", we are referring to information
                that identifies, relates to, describes or can be associated with
                you. The following sections describe the categories and specific
                types of personal information we collect.
              </p>
              <p>Information We Collect Directly from You</p>
              <p>
                Information that you directly submit to us through our Services
                may include:
              </p>
              <u1>
                <li>
                  Basic contact details including your name, address, phone
                  number, email.
                </li>
                <li>
                  Order information including your name, billing address,
                  shipping address, payment confirmation, email address, phone
                  number.
                </li>
                <li>
                  Account information including your username, password,
                  security questions
                </li>
                <li>
                  Shopping information including the items you view, put in your
                  cart or add to your wishlist.
                </li>
                <li>
                  Customer support information including the information you
                  choose to include in communications with us, for example, when
                  sending a message through the Services.
                </li>
                <p>
                  Some features of the Services may require you to directly
                  provide us with certain information about yourself. You may
                  elect not to provide this information, but doing so may
                  prevent you from using or accessing these features.
                </p>
              </u1>
              <p>Information We Collect through Cookies</p>
              <p>
                We also automatically collect certain information about your
                interaction with the Services . To do this, we may use cookies,
                pixels and similar technologies . Usage Data may include
                information about how you access and use our Site and your
                account, including device information, browser information,
                information about your network connection, your IP address and
                other information regarding your interaction with the Services.
              </p>
              <p>Information We Obtain from Third Parties</p>
              <p>
                Finally, we may obtain information about you from third parties,
                including from vendors and service providers who may collect
                information on our behalf, such as:
              </p>
              <ui>
                <li>
                  Companies who support our Site and Services, such as Shopify.
                </li>
                <li>
                  Our payment processors, who collect payment information (e.g.,
                  bank account, credit or debit card information, billing
                  address) to process your payment in order to fulfill your
                  orders and provide you with products or services you have
                  requested, in order to perform our contract with you.
                </li>
                <li>
                  When you visit our Site, open or click on emails we send you,
                  or interact with our Services or advertisements, we, or third
                  parties we work with, may automatically collect certain
                  information using online tracking technologies such as pixels,
                  web beacons, software developer kits, third-party libraries,
                  and cookies.
                </li>
              </ui>
              <p>
                Any information we obtain from third parties will be treated in
                accordance with this Privacy Policy. We are not responsible or
                liable for the accuracy of the information provided to us by
                third parties and are not responsible for any third party's
                policies or practices. For more information, see the section
                below, Third Party Websites and Links.
              </p>
              <h5>How We Use Your Personal Information</h5>
              <ul>
                <li>
                  Providing Products and Services. We use your personal
                  information to provide you with the Services in order to
                  perform our contract with you, including to process your
                  payments, fulfill your orders, to send notifications to you
                  related to you account, purchases, returns, exchanges or other
                  transactions, to create, maintain and otherwise manage your
                  account, to arrange for shipping, facilitate any returns and
                  exchanges and to enable you to post reviews.
                </li>
                <li>
                  Marketing and Advertising. We use your personal information
                  for marketing and promotional purposes, such as to send
                  marketing, advertising and promotional communications by
                  email, text message or postal mail, and to show you
                  advertisements for products or services. This may include
                  using your personal information to better tailor the Services
                  and advertising on our Site and other websites.
                </li>
                <li>
                  Security and Fraud Prevention. We use your personal
                  information to detect, investigate or take action regarding
                  possible fraudulent, illegal or malicious activity. If you
                  choose to use the Services and register an account, you are
                  responsible for keeping your account credentials safe. We
                  highly recommend that you do not share your username,
                  password, or other access details with anyone else. If you
                  believe your account has been compromised, please contact us
                  immediately.
                </li>
                <li>
                  Communicating with you. We use your personal information to
                  provide you with customer support and improve our Services.
                  This is in our legitimate interests in order to be responsive
                  to you, to provide effective services to you, and to maintain
                  our business relationship with you.
                </li>
              </ul>
              <h5>Cookies</h5>
              <p>
                Like many websites, we use Cookies on our Site. We use Cookies
                to power and improve our Site and our Services (including to
                remember your actions and preferences), to run analytics and
                better understand user interaction with the Services (in our
                legitimate interests to administer, improve and optimize the
                Services). We may also permit third parties and services
                providers to use Cookies on our Site to better tailor the
                services, products and advertising on our Site and other
                websites.
              </p>
              <p>
                Most browsers automatically accept Cookies by default, but you
                can choose to set your browser to remove or reject Cookies
                through your browser controls. Please keep in mind that removing
                or blocking Cookies can negatively impact your user experience
                and may cause some of the Services, including certain features
                and general functionality, to work incorrectly or no longer be
                available. Additionally, blocking Cookies may not completely
                prevent how we share information with third parties such as our
                advertising partners.
              </p>
              <h5>How We Disclose Personal Information</h5>
              <p>
                In certain circumstances, we may disclose your personal
                information to third parties for legitimate purposes subject to
                this Privacy Policy. Such circumstances may include:
              </p>
              <ul>
                <li>
                  With vendors or other third parties who perform services on
                  our behalf (e.g., IT management, payment processing, data
                  analytics, customer support, cloud storage, fulfillment and
                  shipping).
                </li>
                <li>
                  With business and marketing partners, including Shopify, to
                  provide services and advertise to you Our business and
                  marketing partners will use your information in accordance
                  with their own privacy notices.
                </li>
                <li>
                  When you direct, request us or otherwise consent to our
                  disclosure of certain information to third parties, such as to
                  ship you products or through your use of social media widgets
                  or login integrations, with your consent.
                </li>
                <li>
                  With our affiliates or otherwise within our corporate group,
                  in our legitimate interests to run a successful business.
                </li>
                <li>
                  In connection with a business transaction such as a merger or
                  bankruptcy, to comply with any applicable legal obligations
                  (including to respond to subpoenas, search warrants and
                  similar requests), to enforce any applicable terms of service,
                  and to protect or defend the Services, our rights, and the
                  rights of our users or others.
                </li>
              </ul>
              <p>
                We have, in the past 12 months disclosed the following
                categories of personal information and sensitive personal
                information (denoted by *) about users for the purposes set out
                above in "How we Collect and Use your Personal Information" and
                "How we Disclose Personal Information":
              </p>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Categories of Recipients</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul>
                        <li>
                          Identifiers such as basic contact details and certain
                          order and account information
                        </li>
                        <li>
                          Commercial information such as order information,
                          shopping information and customer support information
                        </li>
                        <li>
                          Internet or other similar network activity, such as
                          Usage Data
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>
                          Vendors and third parties who perform services on our
                          behalf (such as Internet service providers, payment
                          processors, fulfillment partners, customer support
                          partners and data analytics providers)
                        </li>
                        <li>Business and marketing partners</li>
                        <li>Affiliates</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                We do not use or disclose sensitive personal information for the
                purposes of inferring characteristics about you.
              </p>
              <p>User Generated Content</p>
              <p>
                The Services may enable you to post product reviews and other
                user-generated content. If you choose to submit user generated
                content to any public area of the Services, this content will be
                public and accessible by anyone.
              </p>
              <p>
                We do not control who will have access to the information that
                you choose to make available to others, and cannot ensure that
                parties who have access to such information will respect your
                privacy or keep it secure. We are not responsible for the
                privacy or security of any information that you make publicly
                available, or for the accuracy, use or misuse of any information
                that you disclose or receive from third parties.
              </p>
              <h5>Third Party Websites and Links</h5>
              <p>
                Our Site may provide links to websites or other online platforms
                operated by third parties. If you follow links to sites not
                affiliated or controlled by us, you should review their privacy
                and security policies and other terms and conditions. We do not
                guarantee and are not responsible for the privacy or security of
                such sites, including the accuracy, completeness, or reliability
                of information found on these sites. Information you provide on
                public or semi-public venues, including information you share on
                third-party social networking platforms may also be viewable by
                other users of the Services and/or users of those third-party
                platforms without limitation as to its use by us or by a third
                party. Our inclusion of such links does not, by itself, imply
                any endorsement of the content on such platforms or of their
                owners or operators, except as disclosed on the Services.
              </p>
              <h5>Children’s Data</h5>
              <p>
                The Services are not intended to be used by children, and we do
                not knowingly collect any personal information about children.
                If you are the parent or guardian of a child who has provided us
                with their personal information, you may contact us using the
                contact details set out below to request that it be deleted.
              </p>
              <p>
                As of the Effective Date of this Privacy Policy, we do not have
                actual knowledge that we “share” or “sell” (as those terms are
                defined in applicable law) personal information of individuals
                under 16 years of age.
              </p>
              <h5>Security and Retention of Your Information</h5>
              <p>
                Please be aware that no security measures are perfect or
                impenetrable, and we cannot guarantee “perfect security.” In
                addition, any information you send to us may not be secure while
                in transit. We recommend that you do not use unsecure channels
                to communicate sensitive or confidential information to us.
              </p>
              <p>
                How long we retain your personal information depends on
                different factors, such as whether we need the information to
                maintain your account, to provide the Services, comply with
                legal obligations, resolve disputes or enforce other applicable
                contracts and policies.
              </p>
              <h5>Your Rights and Choices</h5>
              <p>
                Depending on where you live, you may have some or all of the
                rights listed below in relation to your personal information.
                However, these rights are not absolute, may apply only in
                certain circumstances and, in certain cases, we may decline your
                request as permitted by law.
              </p>
              <ul>
                <li>
                  Right to Access / Know. You may have a right to request access
                  to personal information that we hold about you, including
                  details relating to the ways in which we use and share your
                  information.
                </li>
                <li>
                  Right to Delete. You may have a right to request that we
                  delete personal information we maintain about you.
                </li>
                <li>
                  Right to Correct. You may have a right to request that we
                  correct inaccurate personal information we maintain about you.
                </li>
                <li>
                  Right of Portability. You may have a right to receive a copy
                  of the personal information we hold about you and to request
                  that we transfer it to a third party, in certain circumstances
                  and with certain exceptions.
                </li>
                <li>
                  Restriction of Processing: You may have the right to ask us to
                  stop or restrict our processing of personal information.
                </li>
                <li>
                  Withdrawal of Consent: Where we rely on consent to process
                  your personal information, you may have the right to withdraw
                  this consent.
                </li>
                <li>
                  Appeal: You may have a right to appeal our decision if we
                  decline to process your request. You can do so by replying
                  directly to our denial.
                </li>
                <li>
                  Managing Communication Preferences: We may send you
                  promotional emails, and you may opt out of receiving these at
                  any time by using the unsubscribe option displayed in our
                  emails to you. If you opt out, we may still send you
                  non-promotional emails, such as those about your account or
                  orders that you have made.
                </li>
              </ul>
              <p>
                You may exercise any of these rights where indicated on our Site
                or by contacting us using the contact details provided below.
              </p>
              <p>
                We will not discriminate against you for exercising any of these
                rights. We may need to collect information from you to verify
                your identity, such as your email address or account
                information, before providing a substantive response to the
                request. In accordance with applicable laws, You may designate
                an authorized agent to make requests on your behalf to exercise
                your rights. Before accepting such a request from an agent, we
                will require that the agent provide proof you have authorized
                them to act on your behalf, and we may need you to verify your
                identity directly with us. We will respond to your request in a
                timely manner as required under applicable law.
              </p>
              <h5>Complaints</h5>
              <p>
                If you have complaints about how we process your personal
                information, please contact us using the contact details
                provided below. If you are not satisfied with our response to
                your complaint, depending on where you live you may have the
                right to appeal our decision by contacting us using the contact
                details set out below, or lodge your complaint with your local
                data protection authority.
              </p>
              <h5>International Users</h5>
              <p>
                Please note that we may transfer, store and process your
                personal information outside the country you live in, including
                the United States. Your personal information is also processed
                by staff and third party service providers and partners in these
                countries.
              </p>
              <p>
                If we transfer your personal information out of Europe, we will
                rely on recognized transfer mechanisms like the European
                Commission's Standard Contractual Clauses, or any equivalent
                contracts issued by the relevant competent authority of the UK,
                as relevant, unless the data transfer is to a country that has
                been determined to provide an adequate level of protection.
              </p>
              <h5>Contact</h5>
              <p>
                Should you have any questions about our privacy practices or
                this Privacy Policy, or if you would like to exercise any of the
                rights available to you, please call{" "}
                <span className="text-danger">[8606406896]</span> or email us at
                yasfibussiness@gmail.com or contact us at Yasfi, Nettayam Road
                velamanoor p.o kerala india, 691574 kollam KL, India.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ background: "rgb(251 214 214)" }}
            >
              <h1 className="modal-title " id="exampleModalLabe2">
                Shipping Policy
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <h5>Order Processing:</h5>
              <ul>
                <li>
                  All orders are processed within 2 business days. Orders are
                  not shipped or delivered on weekends or holidays, unless
                  otherwise specified.
                </li>
                <li>
                  If we are experiencing a high volume of orders, shipments may
                  be delayed by a few days. Please allow additional days in
                  transit for delivery. If there will be a significant delay in
                  the shipment of your order, we will contact you via email or
                  telephone.
                </li>
              </ul>
              <h5>Shipping Rates & Delivery Estimates:</h5>
              <ul>
                <li>
                  Shipping charges for your order will be calculated and
                  displayed at checkout.
                </li>
                <li>
                  Delivery estimates will be provided once your order has been
                  processed and shipped. Please note that delivery times may
                  vary depending on your location and chosen shipping method.
                </li>
              </ul>
              <h5>Shipment Confirmation & Order Tracking:</h5>
              <ul>
                <li>
                  You will receive a shipment confirmation email once your order
                  has shipped containing your tracking number(s). The tracking
                  number will be active within 24 hours.
                </li>
              </ul>
              <h5>Customs, Duties, and Taxes:</h5>
              <ul>
                <li>
                  Yasfi is not responsible for any customs and taxes applied to
                  your order. All fees imposed during or after shipping are the
                  responsibility of the customer (tariffs, taxes, etc.).
                </li>
              </ul>
              <h5>Damages:</h5>
              <ul>
                <li>
                  Yasfi is not liable for any products damaged or lost during
                  shipping. If you received your order damaged, please contact
                  the shipment carrier or our support team directly to file a
                  claim. Please save all packaging material and damaged goods
                  before filing a claim.
                </li>
                <h5>International Shipping Policy:</h5>
                <li>
                  We currently ship to addresses within [list of
                  countries/regions]. Please note that customs policies vary
                  widely from country to country. We recommend contacting your
                  local customs office for more information before placing your
                  order.
                </li>
              </ul>
              <h5>Returns Policy:</h5>
              <ul>
                <li>
                  Our Return & Refund Policy provides detailed information about
                  options and procedures for returning your order.
                </li>
              </ul>
              <h5>Questions:</h5>
              <ul>
                <li>
                  If you have any questions about the shipping policy, please
                  contact us at [contact information].
                </li>
              </ul>
              <h5>Changes to this Shipping Policy:</h5>
              <ul>
                <li>
                  Yasfi reserves the right to modify this shipping policy at any
                  time. Changes and clarifications will take effect immediately
                  upon their posting on the website.
                </li>
              </ul>
              <p>
                By placing an order with Yasfi, you signify your understanding
                and agreement to the above shipping policy.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal4"
        tabindex="-1"
        aria-labelledby="exampleModalLabel4"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ background: "rgb(251 214 214)" }}
            >
              <h1 className="modal-title " id="exampleModalLabe2">
                Terms Of Service
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start"></div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal5"
        tabindex="-1"
        aria-labelledby="exampleModalLabel5"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ background: "rgb(251 214 214)" }}
            >
              <h1 className="modal-title " id="exampleModalLabe2">
                {" "}
                Contact Information
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
              <p>
                Trade name: <span className="text-info">Yasfi</span>
              </p>
              <p>
                Phone number: <span className="text-info">8606406896</span>
              </p>
              <p>
                Email:{" "}
                <span className="text-info">yasfibussiness@gmail.com</span>
              </p>
              <p>
                Physical address:{" "}
                <span className="text-info">
                  Yasfi, Nettayam Road velamanoor p.o kerala india, 691574
                  kollam KL, India
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
