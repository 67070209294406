import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const ThankYou = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="thankyou container d-flex flex-column align-items-center justify-content-center">
      <h1 className="text-center">Thank You For Purchase</h1>
      <Button onClick={handleBackToHome} className="btn btn-black mt-3">
        Back to Home
      </Button>
    </div>
  );
};

export default ThankYou;
