import { useState } from "react";
import axios from "axios";
import "./Contact.scss";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phno: "",
    comment: "",
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phno) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phno);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.name.trim() || !validateName(data.name)) {
      alert("Please enter a valid name");
      document.getElementById("name").focus();
      return;
    }
    if (!data.phno.trim() || !validatePhoneNumber(data.phno)) {
      alert("Please enter a valid mobile number");
      document.getElementById("phno").focus();
      return;
    }
    if (!data.email.trim() || !validateEmail(data.email)) {
      alert("Please enter a valid email");
      document.getElementById("email").focus();
      return;
    }

    try {
      await submitData();
      setData({
        name: "",
        email: "",
        phno: "",
        comment: "",
      });
      // alert("Message sent successfully!");
    } catch (error) {
      console.error(error);
      alert("An error occurred while sending the message.");
    }
  };

  const submitData = async () => {
    setShowSuccessMessage(true);
    setTimeout(() => 
      setShowSuccessMessage(false)
    , 3000);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5002/yasfi/contact-us",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
      console.log("Data sent successfully", response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="container-sm contact-page">
        <h1>Contact</h1>
        <p>
          Message Yasfi business on WhatsApp: 
          <a href="https://wa.me/918606406896">https://wa.me/918606406896</a>
        </p>
        <h4>If you have any kind of complaints or issues let us know below</h4>

        <form onSubmit={handleSubmit}>
          <div className="form-inputs">
            <input
              type="text"
              placeholder="Name"
              style={{ width: "50%" }}
              onChange={handleChange}
              id="name"
              value={data.name}
            />
            <input
              type="email"
              placeholder="Email*"
              style={{ width: "50%" }}
              onChange={handleChange}
              id="email"
              value={data.email}
            />
          </div>

          <div className="form-inputs">
            <input
              type="tel"
              placeholder="Phone Number"
              onChange={handleChange}
              id="phno"
              value={data.phno}
            />
          </div>

          <div className="form-inputs">
            <textarea
              name="Comment"
              id="comment"
              placeholder="Comment"
              onChange={handleChange}
              value={data.comment}
            ></textarea>
          </div>

         {
          showSuccessMessage && (
            <h3 style={{color:"green"}}>Message sent sucessfully</h3>

          )
         }

          <button type="submit">Send</button>
        </form>
      </div>
    </>
  );
};

export default Contact;
