import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Checkout.scss";
import Footer from "../Checkout/Footer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const allStatesOfIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];

const Checkout = () => {
  const { state } = useLocation();
  const { quantity, totalAmounts, name, image, id, product_Price } =
    state || {};
  console.log(product_Price);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "1",
    email: "",
    mobile: "",
    address: "",
    city: "",
    state: "Andra Pradesh",
    postalCode: "",
    save_address: "",
    billing_address: "1",
    ip_address: "1",
    amount: "",
  });
  const count = quantity;
  const [states, setStates] = useState(allStatesOfIndia);
  const [isChecked, setIsChecked] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [useShippingAsBilling, setUseShippingAsBilling] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [products, setProducts] = useState(
    location.state?.products || [{ id, quantity, image, name }]
  );

  let check = products.map((el) => {
    return el.color;
  });
  console.log(check);

  const [totalAmount, setTotalAmount] = useState(
    location.state?.totalAmount || ""
  );
  const { quantities } = location?.state;
  const { color } = location?.state;
  console.log(color);

  const [showMessage, setShowMessage] = useState(false);

  console.log(products, totalAmount, quantities, color, "quantityyyyy");

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    console.log(e.target.value, "hello");
  };

  const handleUseShippingAsBillingChange = () => {
    setUseShippingAsBilling(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(value);
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const productDetails = products?.map((product) => ({
      product_id: product.id,
      quantity: product.quantity,
      color: color ? color : products[0].color,
    }));

    console.log(productDetails);

    const requestData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mobile: formData.mobile,
      address: `${formData.address},${formData.save_address},${formData.city},${formData.state}, ${formData.postalCode}`,
      city: formData.city,
      state: formData.state,
      save_address: formData.save_address,
      billing_address: formData.billing_address,
      ip_address: formData.ip_address,
      product_details: productDetails,
      amount: totalAmount || totalAmounts,
      payment_method: paymentMethod,
    };

    try {
      console.log("Submitting form data:", requestData);
      const response = await axios.post(
        "https://lunarsenterprises.com:5002/yasfi/add-order",
        requestData
      );
      console.log("Form data submitted:", response.data);
      console.log(response.data.paymentLinkUrl);
      console.log(products);
      console.log(requestData);

      setProducts([]);
      setTotalAmount();
      navigate("/thankyou");
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
      navigate("/thankyou");

      console.log(
        response,
        "---------------------------------------------------------------------------"
      );
      if (response.data.result) {
        localStorage.removeItem("products");
        if (paymentMethod === "online" && response.data.paymentLinkUrl) {
          window.location.href = response.data.paymentLinkUrl;
        } else {
          navigate("/checkout");
        }
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      setIsLoading(false);
      navigate("/thankyou");
    }
  };

  return (
    <div className="container">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row">
          <div className="col-lg-6 br">
            <>
              <div className="contact mt-5">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h3>Contact</h3>
                    <div className="mt-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-check mt-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="subscribe"
                        checked={formData.subscribe}
                        onChange={handleChange}
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Email me with news and offers
                      </label>
                    </div>
                  </>
                )}
              </div>

              <div className="delivery mt-5">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <h3>Delivery</h3>
                    <div className="col-12">
                      <select
                        name="country"
                        className="form-control form-select mb-3 p-3 rounded-3"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="India">India</option>
                      </select>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12 mt-3">
                      <input
                        type="text"
                        name="first_name"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Full Name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-6 mt-3">
                      <input
                        name="address"
                        type="text"
                        placeholder="House name/ building name"
                        className="form-control p-3 rounded-3"
                        value={formData.address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <input
                        name="save_address"
                        type="text"
                        placeholder="Area/Colony"
                        className="form-control p-3 rounded-3"
                        value={formData.save_address}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12 col-md-4 mt-3">
                      <input
                        name="city"
                        type="text"
                        placeholder="City"
                        className="form-control p-3 rounded-3"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <select
                        name="state"
                        className="form-control form-select p-3 rounded-3"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      >
                        {states.map((state, index) => (
                          <option key={index}>{state}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-4 mt-3">
                      <input
                        name="postalCode"
                        type="text"
                        placeholder="Pincode"
                        className="form-control p-3 rounded-3"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <input
                        type="tel"
                        name="mobile"
                        className="form-control p-3 rounded-3 mt-1"
                        placeholder="Phone"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="form-check mt-3">
                {isLoading ? (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subscribe"
                      checked={formData.subscribe}
                      onChange={handleChange}
                      id="flexCheckSaveInfo"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckSaveInfo"
                    >
                      Save this information for next time
                    </label>
                  </>
                )}
              </div>

              {isLoading ? (
                <>
                  <div className="skeleton">
                    <div className="skeleton-text"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="shipping mt-5">
                    <h5>Shipping method</h5>
                  </div>

                  <div className="">
                    Enter your shipping address to view available shipping
                    methods.
                  </div>
                </>
              )}
              {/* Payment */}

              <div className="mt-5">
                <div className="payment">
                  {isLoading ? (
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                      <div className="skeleton-text"></div>
                    </div>
                  ) : (
                    <>
                      <h3>Payment</h3>
                      <p>All transactions are secure and encrypted.</p>
                    </>
                  )}
                </div>
                <div className="box-border">
                  <div className="form-check bd">
                    {isLoading ? (
                      <div className="skeleton">
                        <div className="skeleton-text"></div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        {/* <div className="p-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="paymentMethod"
                            id="online"
                            value="online"
                            checked={paymentMethod === "online"}
                            onChange={handlePaymentMethodChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="online"
                          >
                            Pay Online
                          </label>
                        </div> */}

                        {isLoading ? (
                          <div className="skeleton">
                            <div className="skeleton-icon"></div>
                          </div>
                        ) : (
                          <div className="ms-auto gap-3 p-md-3 p-2"></div>
                        )}
                      </div>
                    )}
                  </div>

                  {paymentMethod === "creditCard" && (
                    <>
                      <div className="row">
                        {isLoading ? (
                          <div className="skeleton p-3">
                            <div className="skeleton-text"></div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="form-check mt-3 p-3 bd">
                        <div className="mx-3">
                          {isLoading ? (
                            <div className="skeleton p-3">
                              <div className="skeleton-text"></div>
                            </div>
                          ) : (
                            <>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                id="flexCheckBillingAddressCreditCard"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckBillingAddressCreditCard"
                              >
                                Use shipping address as billing address
                              </label>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-check bd mt-3">
                    {isLoading ? (
                      <div className="skeleton p-3">
                        <div className="skeleton-text"></div>
                      </div>
                    ) : (
                      <div className="p-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paymentMethod"
                          id="cashOnDelivery"
                          value="cashOnDelivery"
                          checked={paymentMethod === "cashOnDelivery"}
                          onChange={handlePaymentMethodChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="cashOnDelivery"
                        >
                          Cash on Delivery (cod)
                        </label>
                      </div>
                    )}
                  </div>
                  {paymentMethod === "cashOnDelivery" && (
                    <div className="d-flex justify-content-center align-items-center p-3">
                      Pay at your Doorstep
                    </div>
                  )}
                </div>

                {paymentMethod === "cashOnDelivery" && (
                  <>
                    <h5 className="mt-4">Billing Address</h5>
                    <div className="box-border">
                      <div className="form-check mt-3 p-3 mb-3 bd">
                        <div className="mx-3 p-2 bd">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="billingOption"
                            id="same"
                            value="same"
                            checked={!useShippingAsBilling}
                            onChange={() => setUseShippingAsBilling(false)}
                          />
                          <label
                            className="form-check-label text-dark"
                            htmlFor="same"
                          >
                            Same as shipping address
                          </label>
                        </div>
                        <div className="mx-3 p-2 bd">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="billingOption"
                            id="flexCheckBillingAddressCOD"
                            checked={useShippingAsBilling}
                            onChange={() => setUseShippingAsBilling(true)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckBillingAddressCOD"
                          >
                            Use shipping address as billing address
                          </label>
                        </div>

                        {useShippingAsBilling && (
                          <div className="mb-3 mt-2">
                            <div className="col-12 mt-3">
                              <select
                                name="billingCountry"
                                className="form-control form-select mb-3 p-3 rounded-3"
                                value={formData.billingCountry || ""}
                                onChange={handleChange}
                                required
                              >
                                <option value="India">India</option>
                              </select>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-3">
                                <input
                                  type="text"
                                  name="first_name"
                                  className="form-control p-3 rounded-3 mt-1"
                                  placeholder="Full Name"
                                  value={formData.first_name}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-6 mt-3">
                                <input
                                  name="address"
                                  type="text"
                                  placeholder="Area/Colony"
                                  className="form-control p-3 rounded-3"
                                  value={formData.save_address}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-6 mt-3">
                                <input
                                  name="billing_address"
                                  type="text"
                                  placeholder="Building Address"
                                  className="form-control p-3 rounded-3"
                                  value={formData.billing_address}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-4 mt-3">
                                <input
                                  name="city"
                                  type="text"
                                  placeholder="City"
                                  className="form-control p-3 rounded-3"
                                  value={formData.city || ""}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-12 col-md-4 mt-3">
                                <select
                                  name="state"
                                  className="form-control form-select p-3 rounded-3"
                                  value={formData.state}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="kerala">kerala</option>
                                  {states.map((state) => (
                                    <option key={state.code} value={state.code}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-12 mt-3">
                                <input
                                  type="tel"
                                  name="mobile"
                                  className="form-control p-3 rounded-3 mt-1"
                                  placeholder="Phone"
                                  value={formData.mobile || ""}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* bcdscdbc starts here */}
              <div className="col-lg-6 mt-5 p-5" id="media-show">
                <div className="fixed">
                  {products?.map((data) => {
                    return (
                      <div className="d-flex" style={{ marginBottom: "20px" }}>
                        <div className="position-relative">
                          {isLoading ? (
                            <div className="skeleton">
                              <div className="skeleton-image"></div>
                            </div>
                          ) : (
                            <>
                              <div className="position-relative">
                                <img
                              
                                  src={`https://lunarsenterprises.com:3008/${data.image}`}
                                  alt="Product"
                                  className="img-fluid rounded"
                                  style={{ height: "50px", width: "50px" }}
                                />
                                {data.quantity > 0 && (
                                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                                    {data.quantity}
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <div className="mx-3">
                          {isLoading ? (
                            <div className="skeleton">
                              <div className="skeleton-text"></div>
                              <div className="skeleton-text"></div>
                            </div>
                          ) : (
                            <>
                              <p className="order-name">{data.name}</p>
                              <h6 className="order fs-6"></h6>
                            </>
                          )}
                        </div>
                        <div className="ms-auto d-md-flex align-items-md-center">
                          {isLoading ? (
                            <div className="skeleton">
                              <div className="skeleton-text"></div>
                            </div>
                          ) : (
                            <span className="text-center amt">
                              ₹{product_Price}
                              {data.price}
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  {/*       
    <div className="checkout-product-info">
          {image && <img src={image} alt={name}
          
         
                            className="img-fluid rounded"
                            style={{ height: "50px", width: "50px" }}
          />}
          {quantity > 0 && (
            <span className="quaitystyle b">
              {quantity}
            </span>
          )}
          <div>
          {name}
          </div>
       
        </div>
        */}

                  {isLoading ? (
                    <div className="skeleton">
                      <div className="skeleton-text"></div>
                      <div className="skeleton-text"></div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex mt-5">
                        <p className="fs-6 order">Subtotal</p>
                        <div className="ms-auto">
                          <span className="amt">
                            INR {totalAmount}
                            {totalAmounts}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex">
                        <p className="fs-6 order">Shipping</p>
                        <div className="ms-auto text-end">
                          <span>Enter shipping address</span>
                        </div>
                      </div>

                      <div className="d-flex">
                        <h6>Total</h6>
                        <div className="ms-auto">
                          <span className="amt">
                            INR {totalAmount} {totalAmounts}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* bcdscdbc ends here */}
              {isLoading ? (
                <div className="skeleton p-3">
                  <div className="skeleton-text"></div>
                </div>
              ) : (
                <div>
                  {paymentMethod === "cashOnDelivery" ? (
                    <div className="pay mt-5 mb-4 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="text-center btn fs-4 fw-semibold p-2"
                      >
                        Complete Order
                      </button>
                    </div>
                  ) : (
                    <div className="pay mt-5 mb-4 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="text-center btn fs-4 fw-semibold p-2"
                      >
                        Pay now
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="bb-dot">
                <Footer />
              </div>
            </>
          </div>

          <div className="col-lg-6 mt-5 p-5" id="media-hide">
            <div className="fixed">
              {products.map((data, index) => {
                let colors = check[index] || color;

                return (
                  <div
                    className="d-flex"
                    style={{ marginBottom: "20px" }}
                    key={index}
                  >
                    <div className="position-relative">
                      {isLoading ? (
                        <div className="skeleton">
                          <div className="skeleton-image"></div>
                        </div>
                      ) : (
                        <>
                          <div className="position-relative">
                            <img
                               src={`https://lunarsenterprises.com:3008/${data.image}`}
                              alt="Product"
                              className="img-fluid rounded"
                              style={{ height: "50px", width: "50px" }}
                            />
                            {data.quantity > 0 && (
                              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                                {data.quantity}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="mx-3">
                      {isLoading ? (
                        <div className="skeleton">
                          <div className="skeleton-text"></div>
                          <div className="skeleton-text"></div>
                        </div>
                      ) : (
                        <>
                          <p className="order-name">{data.name}</p>
                          <h6 className="order fs-6"></h6>
                          {colors && <h6>Color: {colors}</h6>}
                        </>
                      )}
                    </div>

                    <div className="ms-auto d-md-flex align-items-md-center">
                      {isLoading ? (
                        <div className="skeleton">
                          <div className="skeleton-text"></div>
                        </div>
                      ) : (
                        <span className="text-center amt">
                          ₹{product_Price}
                          {data.price}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}

              {isLoading ? (
                <div className="skeleton">
                  <div className="skeleton-text"></div>
                  <div className="skeleton-text"></div>
                </div>
              ) : (
                <>
                  <div className="d-flex mt-5">
                    <p className="fs-6 order">Subtotal</p>
                    <div className="ms-auto">
                      <span className="amt">
                        INR {totalAmount}
                        {totalAmounts}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <p className="fs-6 order">Shipping</p>
                    <div className="ms-auto text-end">
                      <span>Enter shipping address</span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <h6>Total</h6>
                    <div className="ms-auto">
                      <span className="amt">
                        INR {totalAmount} {totalAmounts}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      {showMessage && (
        <div
          className="success-message"
          style={{
            zIndex: "9999999",
            backgroundColor: "green",
            textAlign: "center",
            position: "fixed",
            top: "100px",
            left: "40%",
            width: "300px",
            height: "50px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Order Placed Successfully
        </div>
      )}
    </div>
  );
};

export default Checkout;
