import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Selected.scss";
import ReactImageMagnify from 'react-image-magnify';
import { Form, Tab, Tabs } from "react-bootstrap";
import 'react-medium-image-zoom/dist/styles.css';
import blacksec from "../../Assets/A4 Black Second Image.jpg";
import Mini from "../../Assets/Mini Fram.jpg";
import nopreview from "../../Assets/nopreview.jpg";
import axios from "axios";
import CommentSection from "../../components/Comments/Comments";
import { toast, ToastContainer } from "react-toastify";

const Selected = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 415);
  const [selectedColor, setSelectedColor] = useState("Red");

  const staticProductData = {
    
    product_price: 1000,
    product_discount_price: 800,
    Images: [
      { image: blacksec, color: "Red" },
      { image: Mini, color: "Blue" },
      { image: blacksec, color: "Green" },
    ],
    product_material: "Sample Material",
    product_stock: 10,
    product_si: "M, L, XL",
    product_description: "Sample product description",
    product_mini_description: "Mini description",
  };

 
  const [mainImage, setMainImage] = useState();
  const [productPrice, setProductPrice] = useState(0); // Initialize with a default value
  const [totalPrice, setTotalPrice] = useState(productPrice * count);

  const handleImageClick = (image) => {
    setMainImage(image);
  };

  const filterUniqueColors = (images) => {
    const seen = new Set();
    return images.filter((img) => {
      const isDuplicate = seen.has(img.color);
      seen.add(img.color);
      return !isDuplicate;
    });
  };



  const Increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    setTotalPrice(productPrice * count);
  }, [count, productPrice]);

  const moveToCart = () => {
    const productDatas = {
      id: p_id, // Static ID
      name: productName,
      image: mainImage,
      quantity: count,
      price: productPrice,
      totalPrice: totalPrice,
      color: selectedColor,
    };

    let existingProducts = localStorage.getItem("products");

    if (existingProducts) {
      let existProductsArr = JSON.parse(existingProducts);

      existProductsArr = existProductsArr.filter((el) => el.id !== p_id);

      existProductsArr.push(productDatas);

      localStorage.setItem("products", JSON.stringify(existProductsArr));
      localStorage.setItem(
        "cartCount",
        JSON.stringify(existProductsArr.length)
      );
      window.dispatchEvent(new Event("storage"));
    } else {
      localStorage.setItem("products", JSON.stringify([productDatas]));
      localStorage.setItem("cartCount", JSON.stringify(1));
      window.dispatchEvent(new Event("storage"));
    }

    navigate("/cart");
  };

  const moveToCartout = () => {
    const productDatas = {
      id: 1, // Static ID
      name: productName,
      image: mainImage,
      quantity: count,
      price: productPrice,
      totalPrice: totalPrice,
      color: selectedColor,
    };

    let existingProducts = localStorage.getItem("products");

    if (existingProducts) {
      let existProductsArr = JSON.parse(existingProducts);

      existProductsArr = existProductsArr.filter((el) => el.id !== 1);

      existProductsArr.push(productDatas);

      localStorage.setItem("products", JSON.stringify(existProductsArr));
      localStorage.setItem(
        "cartCount",
        JSON.stringify(existProductsArr.length)
      );
      window.dispatchEvent(new Event("storage"));
    } else {
      localStorage.setItem("products", JSON.stringify([productDatas]));
      localStorage.setItem("cartCount", JSON.stringify(1));
      window.dispatchEvent(new Event("storage"));
    }

    navigate("/checkout", {
      state: {
        quantity: count,
        totalAmounts: totalPrice,
        image: mainImage,
        name: productName,
        id: 1,
        product_Price: productPrice,
        color: selectedColor,
      },
    });
  };

  const scrollUp = () => {
    window.scrollTo(0, 0);
  };

  const { p_id } = useParams();
  const [uploadImage, setuploadMainImage] = useState();
  const [Viewapi, setViewapi] = useState([]);
  const [activeColor, setActiveColor] = useState(null);

  const [description, setdescription] = useState(null);
  const [productName, setProductName] = useState(null);

  useEffect(() => {
    const Viewdata = async () => {
      try {
        const header = {
          p_id: p_id,
        };

        const response = await axios.post(
          `https://lunarsenterprises.com:3008/newlook/view-product`,
          header
        );

        // Assuming the list is directly in response.data.list
        setViewapi(response.data.list);

        // Set the product price based on the discount_price from the API response
        if (response.data.list.length > 0) {
          setProductPrice(response.data.list[0].discount_price);
          setMainImage(`https://lunarsenterprises.com:3008/${response.data.list[0].image}`);

          setdescription(response.data.list[0].description ||'');

          setProductName(response.data.list[0].name ||'')
        }
       
        console.log(response.data.list);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    Viewdata();
    CommentList()
    
    
  }, [p_id]);

  const items =description? description.split(',').map(item => item.trim()) : [];
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setuploadMainImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleColorClick = (img) => {
    setMainImage(img.image);
    setSelectedColor(img.color);
    setActiveColor(img.color)
  };

  const [selectedSize, setSelectedSize] = useState('');

  // Handler function for changing the selected size
  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const Size =[

    {id:1 ,nameframe :5 * 5}
  ]





  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);

  const handleMouseEnter = (index) => {
    setHoverRating(index);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  
  };

  const handleClick = (index) => {
    setRating(index);
    if (onRatingChange) {
      onRatingChange(index);
      
    }
  };

  const Star = async () => {
    try {
      const reqbody = {
        product_id: p_id,
        rating: rating,
        comment: newComment
      };

      const respo = await axios.post('https://lunarsenterprises.com:3008/newlook/rating', reqbody);

    
      setRating(respo)


    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const handleRatingChange = (rating) => {
    console.log(`New rating is: ${rating}`);
    Star()
  };

  const numStars = 5;
  const onRatingChange = (newRating) => {
    handleRatingChange(newRating);
  };


// list comment

const [commentlist, setcommentlist] = useState([])

const CommentList = async () => {


  try {


    const Commentshow = await axios.post('https://lunarsenterprises.com:3008/newlook/list-comment') 

    setcommentlist(Commentshow.data.list)

    console.log('Commentshow.data',Commentshow.data);
    
  } catch (error) {
    
  }
}



  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://lunarsenterprises.com:3008/newlook/add-comment', { comment: newComment });
      if (response.data.result) {
        toast.success('Comment submitted successfully!');
        setNewComment('');
        CommentList(); // Reload comments
      } else {
        toast.error('Failed to submit comment.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };
  return (
    <>

    <ToastContainer />
      <div className="container product-display">


     
        {Viewapi.map((product, index) => (
          
           

           
               <div key={index} className="selected-product">
                <div className="product-images">
                  <>
                  <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: product.name,
                      isFluidWidth: true,
                      src: mainImage,
                      width: 1200,
                      height: 1800,
                    },
                    largeImage: {
                      src: mainImage,
                      width: 1200,
                      height: 1800,
                    },
                  }}
                />

                  
                  
                    <div className="sub-images" onClick={scrollUp}>
                      {staticProductData.Images.map((img, index) => (
                        <img
                          key={index}
                          src={img.image}
                          alt={`Sub ${index + 1}`}
                          className="sub-image"
                          onClick={() => handleImageClick(img.image)}
                        />
                      ))}
                    </div>
                  </>
                </div>
                <div className="product-details">
                  <h1> {product.name}</h1>
                  <div className="selected-product-price">
                    <del className="selected-price">
                      <span>Rs</span>
                      {product.price}
                    </del>
                    <div className="selected-discounted-price">
                      <span>Rs</span>
                      {totalPrice}
                    </div>
                    <div className="sale-containerdiscountzero">20% OFF</div>
                  </div>
                
                  <div className="color-selection">
                    <h6>Colors</h6>
                    <div className="colors">
                      {filterUniqueColors(staticProductData.Images).map(
                        (img, index) => (
                          <div
                            key={index}
                            className="color-box"
                            onClick={() => handleColorClick(img)}
                            style={{ cursor: "pointer",

                              borderColor: activeColor === img.color ? 'red' : 'black',

                             }}
                          >
                            {img.color}
                          </div>
                        )
                      )}
                    </div>
                    <div>
                    
                    <p>Choose Custom Color</p>
                    <Form.Group controlId="sizeSelector" className="mb-2">
                      <Form.Control
                        as="select"
                        value={selectedSize}
                        onChange={handleSizeChange}
                        custom
                      >
                        <option value="" disabled>Select Your Custom Color</option>
                        {Size.map((size, index) => (
                          <option key={index} value={size.id}>
                            {size.nameframe}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  

                    <p>Upload Your Image</p>
                    <img src={uploadImage || nopreview} alt="Main Product" id=""  className="sampleimage"/>
                    
                  </div>
                  <input type="file" onChange={handleFileChange} />
                  </div>
                  <p style={{ marginBottom: "-10px" }}>Quantity</p>
                  <div className="counting-box">
                    <button onClick={decrement}>
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <div className="count">{count}</div>
                    <button onClick={Increment}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                  <div className="add-buy-buttons">
                    <button
                      style={{ backgroundColor: "transparent" }}
                      onClick={moveToCart}
                    >
                      Add to Cart
                    </button>
                    <button
                      style={{ backgroundColor: "black", color: "white" }}
                      onClick={moveToCartout}
                    >
                      Buy it now
                    </button>
                  </div>

                  <div className="selected-product-details">

                  <div>
                  <p>Description Items</p>
                  <ul>
                    {items.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                    <h6>Name: {productName}</h6>
                    {staticProductData.product_material && (
                      <h6>Material: {staticProductData.product_material}</h6>
                    )}
                    <h6>No. of components: {staticProductData.product_stock}</h6>
                    <h6>Pattern: {staticProductData.product_material}</h6>
                    <h6>Sizes: {staticProductData.product_si}</h6>
                    {staticProductData.product_description && (
                      <p>{staticProductData.product_mini_description}</p>
                    )}

                    
                    {staticProductData.product_description && (
                      <p>{staticProductData.product_description}</p>
                    )}
                  </div>
                </div>
              </div>
            
        
        ))}

         <div className="container describ">
            <Tabs defaultActiveKey="describe" id="product-tabs" className="custom-tabs">
              <Tab eventKey="describe" title="Description" className="custom-tab-content">
                <div>
                   <div>
      <h1>Description Items</h1>
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
                </div>
              </Tab>
             


              <Tab eventKey="Comments" title="Comments" className="custom-tab-content">
              <div>
              <h1>Comments</h1>

              <div className="comment-section">
              <h3>Comments</h3>
              <ul>
                {comments.map((comment, index) => (
                  <li key={index}>{comment.text}</li>
                ))}
              </ul>
              <form onSubmit={handleCommentSubmit}>
                <textarea
                  value={newComment}
                  onChange={handleCommentChange}
                  placeholder="Write a comment..."
                ></textarea>
                <button type="submit">Submit</button>
              </form>
            </div>
              
            </div>
              </Tab>
            </Tabs>
          </div>
      </div>
    </>
  );
};

export default Selected;



