import React, { useState, useRef, useEffect, useContext } from 'react';
import { SearchContext } from '../../context/Searchcontext';
import './Collection.scss';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Collection = () => {
  const { searchResults } = useContext(SearchContext);
  const [posts, setPosts] = useState([]);
  const [selectedSort, setSelectedSort] = useState('');
  const [isPriceDropdownVisible, setIsPriceDropdownVisible] = useState(false);
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const navigate = useNavigate();

  console.log(searchResults);

  // Fetching all products
  const fetchPosts = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5002/yasfi/list-product"
      );
      setPosts(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  // Fetching filtered products
  const fetchPriceFilteredPosts = async (priceFrom, priceTo) => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5002/yasfi/list-product",
        {
          minPrice: priceFrom,
          maxPrice: priceTo,
        }
      );

      console.log(response.data.data);

      const filteredPosts = response.data.data.filter((post) => {
        console.log(post.product_discount_price, priceFrom, priceTo);
        return post.product_discount_price >= priceFrom && post.product_discount_price <= priceTo;
      });

      setPosts(filteredPosts);
      console.log(filteredPosts);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (priceFrom && priceTo) {
      fetchPriceFilteredPosts(priceFrom, priceTo);
    }
  }, [priceFrom, priceTo]);

  // Fetching products with price low to high
  const fetchLowToHighProducts = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5002/yasfi/list-product",
        {
          order_by: selectedSort
        }
      );
      console.log(response.data.data);
      setPosts(response.data.data)

    } catch (error) {
      console.log(error)
    }
  }

  const callOrder = () => {
    fetchLowToHighProducts()
  }

  const priceDropdownRef = useRef(null);

  const handleSortChange = (event) => {
    setSelectedSort(event.target.value);
  };
  console.log(selectedSort)

  const togglePriceDropdown = () => {
    setIsPriceDropdownVisible(!isPriceDropdownVisible);
  };

  const handleReset = () => {
    setPriceFrom('');
    setPriceTo('');
    fetchPosts()
  };

  const handleClickOutside = (event) => {
    if (priceDropdownRef.current && !priceDropdownRef.current.contains(event.target)) {
      setIsPriceDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const productDetails = (product_id) => {
    navigate(`/product/${product_id}`);
    console.log(product_id);
  }

  // Use displayedPosts for rendering products
  const displayedPosts = searchResults && searchResults.length > 0 ? searchResults : posts;

  return (
    <>
      <div className="container-lg products">
        <h1>Our Products</h1>
        <div className="filter">
          <div className="sub-filter">
            <p>Filter :</p>
            <p className='sec' onClick={togglePriceDropdown}>
              Price
              <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: "11px" }} />
            </p>
            {isPriceDropdownVisible && (
              <div className="price-dropdown" ref={priceDropdownRef}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p>The highest price is Rs. 999.00</p>
                  <button onClick={handleReset}>Reset</button>
                </div>

                <div className="price-inputs">

                  <div className="price-input">
                    <span>₹</span>
                    <input
                      type="number"
                      placeholder="From"
                      value={priceFrom}
                      onChange={(e) => setPriceFrom(e.target.value)}
                    />
                  </div>

                  <div className="price-input">
                    <span>₹</span>
                    <input
                      type="number"
                      placeholder="To"
                      value={priceTo}
                      onChange={(e) => setPriceTo(e.target.value)}
                    />
                  </div>

                </div>
              </div>
            )}
          </div>
          <div className="sub-filter">

            <p>Sort by:</p>
            <select value={selectedSort} onChange={handleSortChange} onClick={callOrder}>
              <option value="">Select an option</option>
              <option value="low_to_high" className='low'>Price: Low to High</option>
              <option value="high_to_low">Price: High to Low</option>
              <option value="asc">Name: A to Z</option>
              <option value="desc">Name: Z to A</option>
            </select>
            <p>{displayedPosts.length}</p>

          </div>
        </div>

        <div className="selected-filters">
          {priceFrom && priceTo && (
            <div className="filter-tag">
              <div className='round'>
                Rs. {priceFrom} - Rs. {priceTo}
                <button onClick={handleReset}>×</button>
              </div>

              <button onClick={handleReset}>Remove all</button>
            </div>

          )}
        </div>

        <div className="collection-product-display">
          {displayedPosts.length > 0
            ? displayedPosts.map((post) => (

              <div className="collection-product-card" key={post.product_id} onClick={() => productDetails(post.product_id)}>
                <div className="collection-product-image-container">

                  <img
                    src={`https://lunarsenterprises.com:5002/${post.image[0]?.image}`}
                    alt={post.product_name}
                    className="collection-default"
                  />

                  <div className="sale-container">sale</div>
                </div>
                <div className="product-name">
                  <h6>{post.product_name}</h6>
                </div>
                <div className="product-price-details">
                  <del className="price">
                    <span>Rs</span>
                    {post.product_price}
                  </del>
                  <div className="discounted-price">
                    <span>Rs</span>
                    {post.product_discount_price}
                  </div>
                </div>
              </div>
            ))
            : (
              <div>
                <h1></h1>
              </div>
            )
          }

        </div>
      </div>
    </>
  );
}

export default Collection;
