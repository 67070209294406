import React, { useState, useEffect, useContext, useRef } from "react";
import { SearchContext } from "../../context/Searchcontext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Caraosel from "../../components/Caraosel/Carausel";
import "./Home.scss";
import Whatsapp from "../../components/Whatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageMagnifier from "../../components/ImageMagnifier";
import blacksec from "../../Assets/A4 Black Second Image.jpg";
const Home = () => {
  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const goToSlide = (index) => {
    if (splideRef.current) {
      splideRef.current.go(index);
      setActiveIndex(index);
    }
  };

  const [posts, setPosts] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [cart, setCart] = useState([]);
  const [Premiumlist, setPremiumList] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [otherProducts, setOtherProducts] = useState([]);
  const navigate = useNavigate();
  const { searchResults } = useContext(SearchContext);

 
  useEffect(() => {
    
    const savedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setFavorites(savedFavorites);
    setCart(savedCart);
  }, []);

 

  const displayedPosts =
    searchResults && searchResults.length > 0 ? searchResults : posts;

  const handleFavoriteClick = (product) => {
    let updatedFavorites = [...favorites];
    const isFavorite = favorites.some(fav => fav.p_id === product.p_id);
    if (isFavorite) {
      updatedFavorites = updatedFavorites.filter(fav => fav.p_id !== product.p_id);
      toast.info("Product removed from favorites");
    } else {
      updatedFavorites.push(product);
      toast.success("Product added to favorites");
    }
    setFavorites(updatedFavorites);
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    window.dispatchEvent(new Event('storage'));
  };

  const handleCartClick = (product) => {
    let updatedCart = [...cart];
    const isInCart = cart.some(item => item.p_id === product.p_id);
    if (isInCart) {
      updatedCart = updatedCart.filter(item => item.p_id !== product.p_id);
      toast.info("Product removed from cart");
    } else {
      updatedCart.push({ ...product, quantity: 1 });
      toast.success("Product added to cart");
    }
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    window.dispatchEvent(new Event('storage'));
  };

  useEffect(() => {
    ListPremium();
    ListProduct();
  }, []);

  const ListPremium = async () => {
    try {
      const response = await axios.post('https://lunarsenterprises.com:3008/newlook/list-premium');
      if (response.data && response.data.result) {
        setPremiumList(response.data.list || []);
      }
    } catch (error) {
      console.error('Error fetching premium list:', error);
    }
  };

const [allproducts, setallproducts] = useState([]);

  const ListProduct = async () => {
    try {
      const response = await axios.post('https://lunarsenterprises.com:3008/newlook/list-products');
      if (response.data && response.data.result) {
        const products = response.data.list || [];
        setallproducts(products);
        setNewArrivals(products.filter(product => product.new_arrival_status === 1));
        setOtherProducts(products.filter(product => product.new_arrival_status === 0));
      }
    } catch (error) {
      console.error('Error fetching product list:', error);
    }
  };
  const createSlug = (name) => {
    if (!name) {
    return ''; // Return an empty string or a default slug if name is not provided
  }
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with dashes
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing dashes
  };
  
  const productDetails = (p_id, name) => {
    const slug = createSlug(name);
    navigate(`/product/${p_id}/${slug}`); // Navigate with both p_id and slug
  };
  
const defaultImage =blacksec
  return (
    <>
      <Whatsapp />
      <ToastContainer />
      <div className="">
        <Caraosel />
      </div>
      <div className="">
      <h3 className="container mt-3 mb-3 heading">Exculsive Frames </h3>
      <div className=" container products ">
        <div className=" product-displays container parent-container" style={{ position: "relative" }}>
          <Splide
            options={{
              perPage: 4,
              rewind: true,
              autoplay: true,
              interval: 6000,
              pagination: false,
              gap: "1rem",
              breakpoints: {
                1200: {
                  perPage: 3,
                  gap: "1rem",
                },
                768: {
                  perPage: 2,
                  gap: "0.5rem",
                },
                480: {
                  perPage: 2,
                  gap: "0.25rem",
                },
              },
            }}
            aria-label="customList"
            ref={splideRef}
            onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
          >
            {allproducts.length > 0 ? (
              allproducts.map((post) => (
                <SplideSlide key={post.p_id}>
                   <div className="product-card" onClick={() => productDetails(post.p_id)}>
                    <div className="product-image-container">
                      <img
                        src={`https://lunarsenterprises.com:3008/${post.image}`}
                        alt={post.name}
                        className="default"
                        style={{borderRadius:10}}
                      />
                      <img
                        src={`https://lunarsenterprises.com:3008/${post.image}`}
                        alt={post.name}
                        className="hover"
                        style={{borderRadius:20}}
                      />
                      <div className="sale-containerdiscount">
                        <p>-{post.discount}%</p>
                      </div>
                      {post.stock === 0 ? (
                        <div className="sale-containersoldout">
                          <p>Sold out</p>
                        </div>
                      ) : null}
                  
                    </div>
                    <div className="namemain">
                    <div className="product-name">
                      <h6>{post.name}</h6>
                    </div>
                    <div className="product-price-details">
                      <del className="price">
                        <span>Rs</span>
                        {post.price}
                      </del>
                      <div className="discounted-price" style={{color:'red'}}>
                        <span>Rs</span>
                        {post.discount_price}
                      </div>
                    </div>
                    <div className={`product-namecart cartaddbox bg-danger ${cart.some(item => item.p_id === post.p_id) ? 'selected' : 'unselected'}`}
                    onClick={(e) => { e.stopPropagation(); handleCartClick(post); }}
                    >
                    <p className="centerrr">
  {cart.some(item => item.p_id === post.p_id) ? 'Remove from Cart' : 'Add to Cart'}
</p>
                    </div>
                    </div>
                  </div>
                </SplideSlide>
              ))
            ) : (
              <div>
                <h1>Products not Availiable in Exculsive</h1>
              </div>
            )}
          </Splide>
        </div>
      </div>
    </div>




         <div className="">
        <h3 className="container mt-3 mb-3 heading">New Arrivals </h3>
        <div className=" container products ">
          <div className=" product-displays container parent-container" style={{ position: "relative" }}>
            <Splide
              options={{
                perPage: 4,
                rewind: true,
                autoplay: true,
                interval: 6000,
                pagination: false,
                gap: "1rem",
                breakpoints: {
                  1200: {
                    perPage: 3,
                    gap: "1rem",
                  },
                  768: {
                    perPage: 2,
                    gap: "0.5rem",
                  },
                  480: {
                    perPage: 2,
                    gap: "0.25rem",
                  },
                },
              }}
              aria-label="customList"
              ref={splideRef}
              onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
            >
              {allproducts.length > 0 ? (
                allproducts.map((post) => (
                  <SplideSlide key={post.p_id}>
               <div className="product-card" onClick={() => productDetails(post.p_id, post.name)}>
                      <div className="product-image-container">
                        <img
                          src={`https://lunarsenterprises.com:3008/${post.image}`}
                          alt={post.name}
                          className="default"
                          style={{borderRadius:10}}
                        />
                        <img
                          src={`https://lunarsenterprises.com:3008/${post.image}`}
                          alt={post.name}
                          className="hover"
                          style={{borderRadius:20}}
                        />
                        <div className="sale-containerdiscount">
                          <p>-{post.discount}%</p>
                        </div>
                        {post.stock === 0 ? (
                          <div className="sale-containersoldout">
                            <p>Sold out</p>
                          </div>
                        ) : null}
                       
                      </div>
                      <div className="namemain">
                      <div className="product-name">
                        <h6>{post.name}</h6>
                      </div>
                      <div className="product-price-details">
                        <del className="price">
                          <span>Rs</span>
                          {post.price}
                        </del>
                        <div className="discounted-price" style={{color:'red'}}>
                          <span>Rs</span>
                          {post.discount_price}
                        </div>
                      </div>
                      <div className={`product-namecart cartaddbox bg-danger ${cart.some(item => item.p_id === post.p_id) ? 'selected' : 'unselected'}`}
                      onClick={(e) => { e.stopPropagation(); handleCartClick(post); }}
                      >
                      <p className="centerrr">
    {cart.some(item => item.p_id === post.p_id) ? 'Remove from Cart' : 'Add to Cart'}
  </p>
                      </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              ) : (
                <div>
                  <h1>Products not Availiable in  New Arrivals</h1>
                </div>
              )}
            </Splide>
          </div>
        </div>
      </div>
      <div className="">
        <h3 className="container mt-3 mb-3 heading">Premium Frames </h3>
        <div className=" container products ">
          <div className=" product-displays container parent-container" style={{ position: "relative" }}>
            <Splide
              options={{
                perPage: 4,
                rewind: true,
                autoplay: true,
                interval: 6000,
                pagination: false,
                gap: "1rem",
                breakpoints: {
                  1200: {
                    perPage: 3,
                    gap: "1rem",
                  },
                  768: {
                    perPage: 2,
                    gap: "0.5rem",
                  },
                  480: {
                    perPage: 2,
                    gap: "0.25rem",
                  },
                },
              }}
              aria-label="customList"
              ref={splideRef}
              onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
            >
              {allproducts.length > 0 ? (
                allproducts.map((post) => (
                  <SplideSlide key={post.p_id}>
                  <div className="product-card bounce " onClick={() => productDetails(post.p_id, post.name)}>
                      <div className="product-image-container">
                        <img
                          src={`https://lunarsenterprises.com:3008/${post.image}`}
                          alt={post.name}
                          className="default"
                          style={{borderRadius:10}}
                        />
                        <img
                          src={`https://lunarsenterprises.com:3008/${post.image}`}
                          alt={post.name}
                          className="hover"
                          style={{borderRadius:20}}
                        />
                        <div className="sale-containerdiscount">
                          <p>-{post.discount}%</p>
                        </div>
                        {post.stock === 0 ? (
                          <div className="sale-containersoldout">
                            <p>Sold out</p>
                          </div>
                        ) : null}
                      
                      </div>
                      <div className="namemain">
                      <div className="product-name">
                        <h6>{post.name}</h6>
                      </div>
                      <div className="product-price-details">
                        <del className="price">
                          <span>Rs</span>
                          {post.price}
                        </del>
                        <div className="discounted-price" style={{color:'red'}}>
                          <span>Rs</span>
                          {post.discount_price}
                        </div>
                      </div>
                      <div className={`product-namecart cartaddbox bg-danger ${cart.some(item => item.p_id === post.p_id) ? 'selected' : 'unselected'}`}
                      onClick={(e) => { e.stopPropagation(); handleCartClick(post); }}
                      >
                      <p className="centerrr">
    {cart.some(item => item.p_id === post.p_id) ? 'Remove from Cart' : 'Add to Cart'}
  </p>
                      </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              ) : (
                <div>
                  <h1>Products not Availiable in Premium</h1>
                </div>
              )}
            </Splide>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
