import React from 'react'
import './Whatsapp.scss'
export default function Whatsapp() {
  return (
    <div className="whatsapp-button">
    <a href="https://wa.me/15551234567" target="_blank">
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
    </a>
  </div>
  )
}
