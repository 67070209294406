import React from 'react';
import './Footer.scss';
import { Navbar, Nav, Modal } from 'react-bootstrap';

const Footer = () => {
  return (
    <div className='Footerstyle'>
    <div className=' container pt-5'>
      <h5 className=' text-center'>Quick links</h5>

      <div className='col-md-12 col-12 justify-content-center align-items-center d-flex '>
        
      <div style={{display:"flex",gap:"20px"}} >

        <Nav.Link href="/refund">
        <p >Refund Policy</p>
        </Nav.Link>

        <Nav.Link href="/privacy">
        <p >Privicy policy</p>
        </Nav.Link>

        <Nav.Link href="/terms">
        <p >Terms of Service</p>
        </Nav.Link>

      </div>
      </div>

     
    






    
    </div>
    <div className='d-flex justify-content-center align-items-center mainpowered pb-3'>
    
    <p className='powered'>© 2024,  Powered by NewLook</p>
    
      </div>
    </div>
  );
}

export default Footer;
