import React, { useContext } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { BannerContext } from '../../context/Banner';
import './Carausel.scss';

function UncontrolledExample() {
  const { banner } = useContext(BannerContext); // Access banner data from context

  if (!Array.isArray(banner)) {
    return <div>Loading...</div>; // Render a loading state while banner is being fetched or if it's not an array
  }

  return (
    <Carousel indicators={false}>
      { 
        banner.map((data) => (
          <Carousel.Item key={data.image} interval={3000}>
            <img 
              src={`https://lunarsenterprises.com:5002/${data.b_image}`} 
              alt="" 
              style={{width:"100%"}} 
              id='zero'
            />
          </Carousel.Item>
        ))
      }
    </Carousel>
  );
}

export default UncontrolledExample;
