import { useState, useContext, useEffect } from "react";
import { Navbar, Nav, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faSearch, faUser, faCartShopping, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { SearchContext } from "../../context/Searchcontext";
import axios from "axios";
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import "./Header.scss";

import Logo from "../../Assets/Logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { setSearchResults } = useContext(SearchContext);
  const [favoriteCount, setFavoriteCount] = useState(() => {
    return JSON.parse(localStorage.getItem("favorites"))?.length || 0;
  });
  const [cartCount, setCartCount] = useState(() => {
    return JSON.parse(localStorage.getItem("cart"))?.length || 0;
  });
  const [discount, setDiscount] = useState([]);

  const fetchBanner = async () => {
    try {
      const response = await axios.get(
        "https://lunarsenterprises.com:5002/yasfi/list-banner"
      );
      setDiscount(response.data.offer);
      console.log(response.data.offer);
    } catch (error) {
      console.error("Error fetching banner:", error);
    }
  };

  useEffect(() => {
    fetchBanner();
    const handleStorageChange = () => {
      setFavoriteCount(JSON.parse(localStorage.getItem("favorites"))?.length || 0);
      setCartCount(JSON.parse(localStorage.getItem("cart"))?.length || 0);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    scrollDown();
  };

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    if (searchText.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      console.log(searchText);

      const response = await axios.post(
        "https://lunarsenterprises.com:5002/yasfi/list-product",
        { search: searchText }
      );
      setSearchResults(response.data.data);
      console.log(response);
    } catch (error) {
      console.error("An error occurred while searching:", error);
    }
  };

  const scrollDown = () => {
    window.scroll(500, 500);
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="sticky">
        <div className="container-fluid boss-header">
         
        
        {Array.isArray(discount) &&
            discount.length > 0 &&
            discount.map((data) => (
              <div className="text-center" key={data.id}>
                {data.o_description}
              </div>
            ))}

          <div className="navmainstyle">
            <Navbar expand="lg" className="boss-header container header">
              <div
                // onClick={toggleContent}
                className="navbar-toggler mx-2"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >

              </div>
              <Navbar.Brand href="/" className="mx-auto">
                <img src={Logo} className="logostyle" alt="logo" />
              </Navbar.Brand>
              <div className="header-icons ml-auto">
                <div className="sideicondiv" onClick={toggleSearch}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="sideicon" />
                </div>
                <div className="sideicondiv">
                  <FontAwesomeIcon icon={faUser} className="sideicon" />
                </div>
                {/** <div className="sideicondiv">
                  <FontAwesomeIcon icon={faHeart} className="sideicon" />
                  {favoriteCount > 0 && (
                    <span className="badgestyle">{favoriteCount}</span>
                  )}
                </div> */}
               
                <div className="sideicondiv"  onClick={() => navigate('/cart')}>
                  <FontAwesomeIcon icon={faCartShopping} className="sideicon" />
                  {cartCount > 0 && (
                    <span className="badgestyle">{cartCount}</span>
                  )}
                </div>
              </div>
            </Navbar>
            <Modal show={isContentVisible} onHide={toggleContent} centered>
              <Modal.Header closeButton>
                <Modal.Title>Menu</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Nav className="ml-auto flex-column">
                  <Nav.Link href="/">Home</Nav.Link>
                  
                </Nav>
              </Modal.Body>
            </Modal>
          </div>
        </div>

        <div className="  maindivall">

        <div className="dropdown-container ">    
        <div className="dropdown-container hovereffecthead categorysection">
          <p className="hovereffectheadsize">All Collections</p>
          <div className="dropdown-content ">
            <p className="sizeframe">4*4 Frame</p>
            <p className="sizeframe">5*5 Frame</p>
            <p className="sizeframe">6*6 Frame</p>
            <p className="sizeframe">8*8 Frame</p>
          </div>
        </div>
      </div>
      
      <div className=" dropdown-container">  
        <div className="dropdown-container hovereffecthead categorysection">
          <p className="hovereffectheadsize">Frame Collections</p>
          <div className="dropdown-content">
          <p className="sizeframe">4*4 Frame</p>
          <p className="sizeframe">5*5 Frame</p>
          <p className="sizeframe">6*6 Frame</p>
          <p className="sizeframe">8*8 Frame</p>
          </div>
        </div>
      </div>
      
      <div className=" dropdown-container">  
        <div className="dropdown-container hovereffecthead categorysection">
          <p className="hovereffectheadsize">Customized Mug</p>
          <div className="dropdown-content">
          <p className="sizeframe">4*4 Frame</p>
          <p className="sizeframe">5*5 Frame</p>
          <p className="sizeframe">6*6 Frame</p>
          <p className="sizeframe">8*8 Frame</p>
          </div>
        </div>
        </div>
      </div>
      </div>


    

      {isSearchVisible && (
        <div className="search-container">
          <input
            type="text"
            placeholder="Search for products..."
            onChange={handleSearch}
            className="search-input"
          />
        </div>
      )}
    </>
  );
};

export default Header;
